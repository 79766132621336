@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .text-input {
    @apply form-input flex-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300
  }


  a {
    @apply text-blue-700 underline;
  }
  p {
    @apply my-4;
  }

  blockquote,
  figure {
    @apply my-4 mx-10;
  }

  hr {
    @apply border;
  }

  h1 {
    @apply text-4xl font-bold my-2;
  }

  h2 {
    @apply text-2xl font-bold my-3;
  }

  h3 {
    @apply text-lg font-bold my-4;
  }

  h4 {
    @apply text-base font-bold my-5;
  }

  h5 {
    @apply text-sm font-bold my-6;
  }

  h6 {
    @apply text-xs font-bold my-10;
  }

  article,
  aside,
  nav,
  section {
    h1 {
      @apply text-2xl font-bold my-3;
    }

    article,
    aside,
    nav,
    section {
      h1 {
        @apply text-lg font-bold my-4;
      }

      article,
      aside,
      nav,
      section {
        h1 {
          @apply text-base font-bold my-5;
        }

        article,
        aside,
        nav,
        section {
          h1 {
            @apply text-sm font-bold my-6;
          }

          article,
          aside,
          nav,
          section {
            h1 {
              @apply text-xs font-bold my-10;
            }
          }
        }
      }
    }
  }

  ul,
  menu {
    @apply list-disc my-1 pl-10;
  }

  ol {
    @apply list-decimal my-4 pl-10;
  }

  ul,
  ol {
    ul {
      list-style-type: circle;
    }

    ul,
    ol {
      ul {
        list-style-type: square;
      }
    }
  }

  dd {
    @apply pl-10;
  }

  dl {
    @apply my-4;
  }

  ul,
  ol,
  menu,
  dl {
    ul,
    ol,
    menu,
    dl {
      margin: 0;
    }
  }

  legend {
    @apply py-0 px-1;
  }

  fieldset {
    @apply my-0 mx-1 pt-0 px-1 pb-2;
  }

  b,
  strong {
    font-weight: bold;
  }

  pre {
    @apply my-4;
  }
}
